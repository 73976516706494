import { Controller } from "@hotwired/stimulus"
import { deepMerge, trackAction, onDocumentInteracted } from '@javascript/javascripts/utils'
import Plyr from 'plyr/dist/plyr.polyfilled.js'

export default class extends Controller {

  static values = {
    options: Object
  }
  static targets = ['player'];

  initialize() {
    this.player = null
    this.completionRanges = Array.from({length: 101}, (_, i) => i % 5 == 0 || i == 0 ? i : null).filter(i => i !== null)

    // onDocumentInteracted(this._boot.bind(this))
    this._boot()
  }

  _boot(){
    let params = this.optionsValue['player_params'] || {}

    if(this.optionsValue.mode === 'carousel'){
      params.muted = false
      params.volume = 100
      params.autoplay = false
    }

    if(this.hasPlayerTarget){
      this.player = new Plyr(this.playerTarget, params)
      // this.player.on('ready', this._togglePlayOnDocumentFocusChange.bind(this))

      this.player.on('ready', () => {
        document.dispatchEvent(new CustomEvent('video.ready', { detail: { player: this.player, identifier: this.optionsValue.identifier } }))
      })

      this.player.on('play', () => {
        document.dispatchEvent(new CustomEvent('video.play', { detail: { player: this.player, identifier: this.optionsValue.identifier } }))
      })
      // this.player.on('ready', () => {
      //   window.loadedVideoPlayerComponents = window.loadedVideoPlayerComponents || {}
      //   const props = { player: this.player }
      //   if(this.options.identifier){
      //     this.props.identifier = this.options.identifier
      //   }
      //   window.loadedVideoPlayerComponents[this.player.id] = props
      //   document.dispatchEvent(new CustomEvent('video.ready', { detail: props }))
      // })
      //
      // if(this.options.identifier){
      //   this.player.on('ready', () => {
      //     window.loadedVideoComponentPlayers = window.loadedVideoComponentPlayers || {}
      //     window.loadedVideoComponentPlayers[this.options.identifier] = this.player
      //     document.dispatchEvent(new CustomEvent('video.ready', { detail: { player: this.player } }))
      //   })
      // }

      if(this.optionsValue.tracking){
        this.player.on('ready', () => { this.handleTracking(this.optionsValue.tracking) });
      }
    }

  }

  handleTracking(options){
    let elapsedSeconds = 0, completion = 0, cache = {};

    this.player.on('timeupdate', () => {
      // elapsedSeconds = Math.round(this.player.currentTime);
      // completion = Math.round((this.player.currentTime * 100) / this.player.duration);
      completion = this._approximateCompletion((this.player.currentTime * 100) / this.player.duration)

      if(this.player.currentTime > 0){
        if(completion % 10 == 0 && cache[`completion_${options.scope}_${completion}`] == null){
          cache[`completion_${options.scope}_${completion}`] = true
          trackAction('videoview', `${options.scope}/completion/${completion}`)
        }
      }
    });
  }

  // videoplayer.elapsedTime is too approximative on videos < 10s so we need to make sure
  // that if elapsedTime == 2.8s, we can compute video % completion with a 5 modulo
  _approximateCompletion(completion){
    const r = this.completionRanges
    return (completion > 99 ? [100] : r.filter((_, i) => { return completion >= r[i] && completion < r[i + 1] }))[0]
  }

  _togglePlayOnDocumentFocusChange() {
    // handles tab change
    const pauseText = "⏸"
    let hidden, visibilityChange;
    if (typeof document.hidden !== "undefined") {
      hidden = "hidden";
      visibilityChange = "visibilitychange";
    } else if (typeof document.msHidden !== "undefined") {
      hidden = "msHidden";
      visibilityChange = "msvisibilitychange";
    } else if (typeof document.webkitHidden !== "undefined") {
      hidden = "webkitHidden";
      visibilityChange = "webkitvisibilitychange";
    }
    if(hidden !== undefined){
      const pauseText = "⏸"
      document.addEventListener(visibilityChange, () => {
        if(document[hidden]){
          this.player.pause()
          if(document.title.indexOf(pauseText) == -1){
            document.title = `${pauseText} ${document.title}`
          }
        } else {
          this.player.play()
          document.title = document.title.replace(pauseText, '')
        }
      }, false);
    }
  }

}
